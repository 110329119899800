import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import {
  Link
} from "react-router-dom";

import { colors, sharedStyles } from "./styles.js";
import Header from "./Header.js";

const styles = StyleSheet.create({
  about: {
    color: colors.text,
    padding: '0 16px',
  },

  feedbackLink: {
    color: colors.blue,
  },

  nowrap: {
    whiteSpace: "nowrap",
  },
});

export default function MarketStatsReminder() {
  return (
    <div className={css(sharedStyles.wrapper)}>
      <Header />
      <div className={css(styles.about)}>
        <p>
          The API is hosted on <a className={css(styles.feedbackLink, styles.nowrap)} href="https://api.eve-echoes-market.com/">api.eve-echoes-market.com</a>, not <span className={css(styles.nowrap)}>eve-echoes-market.com</span>.
        </p>
        <p>
          See the <Link to="/api" className={css(styles.feedbackLink)}>API Docs</Link> for more information.
        </p>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import {
  Link
} from "react-router-dom";

import { colors } from "./styles.js";
import Icon from "./Icon.js";

const styles = StyleSheet.create({
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: colors.text,
    height: 50,
    lineHeight: '50px',
    borderBottom: `1px solid ${colors.blue}`,
    paddingLeft: 16,
    paddingRight: 16,
    display: "flex",
    justifyContent: "space-between",
  },

  mainLink: {
    color: colors.text,
    textDecoration: "none",

    ":visited": {
      color: colors.text,
    },
  },

  hamburgerMenuIcon: {
    paddingLeft: 16,
    cursor: "pointer",
  },

  hamburgerMenuWrapper: {
    position: "relative",
  },

  hamburgerMenu: {
    position: "absolute",
    right: -16,
    top: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    backgroundColor: colors.darkBlue,
    border: `2px solid ${colors.blue}`,
    padding: 16,
    paddingBottom: 0,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },

  menuLink: {
    color: colors.text,
    textDecoration: "none",
    fontSize: 20,
    whiteSpace: 'nowrap',
    lineHeight: 1,
    paddingBottom: 16,

    ":visited": {
      color: colors.text,
    },
  },
});

function HamburgerMenu() {
  const [open, setOpen] = useState(false);

  return (
    <div className={css(styles.hamburgerMenuWrapper)}>
      <div
        className={css(styles.hamburgerMenuIcon)}
        onClick={() => setOpen(!open)}
      >
        <Icon name="hamburger" />
      </div>
      {open && (
        <div className={css(styles.hamburgerMenu)}>
          <Link className={css(styles.menuLink)} to="/about">About</Link>
          <Link className={css(styles.menuLink)} to="/api">API Docs</Link>
        </div>
      )}
    </div>
  );
}

export default function Header() {
  return (
    <div className={css(styles.header)}>
      <Link className={css(styles.mainLink)} to="/">EVE Echoes Market</Link>
      <HamburgerMenu />
    </div>
  );
}

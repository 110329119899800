import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import {
  Link
} from "react-router-dom";

import Header from "./Header.js";
import { colors, sharedStyles } from "./styles.js";

const styles = StyleSheet.create({
  itemHeader: {
    borderBottom: `1px solid ${colors.blue}`,
    padding: '4px 10px',
    color: colors.text,
    textAlign: 'center',
    fontSize: 24,
  },

  changelog: {
    borderTop: `1px solid ${colors.blue}`,
  },

  about: {
    color: colors.text,
    padding: '0 16px',
  },

  feedbackLink: {
    color: colors.blue,
  },
});

const changelog = [
  {
    date: "Feb 21, 2021",
    changes: [
      "Updated website and API with Yoiul special supply boxes, and updated names of many categories and items to match the game.",
    ],
  },
  {
    date: "January 2, 2021",
    changes: [
      "Added many new items to both the website and the CSV. I believe I found all of the new items, but if you find any that are missing please leave feedback.",
    ],
  },
  {
    date: "October 14, 2020",
    changes: [
      "Added browser routes for the different items, so you can bookmark individual item pages and return to them easily",
    ],
  },
  {
    date: "September 20, 2020",
    changes: [
      "Added lowest sell and highest buy to the stats csv",
    ],
  },
  {
    date: "September 19, 2020",
    changes: [
      "Added what I believe are all remaining items. Please leave feedback if you still find missing items",
      "Added a reminder that the API is hosted on a different host than the main website",
      "Added an example of how to import the stats CSV into a Google Sheet",
    ],
  },
  {
    date: "September 17, 2020",
    changes: [
      "Added several hundred items that were missing. Please leave feedback if you still find missing items",
      "Tweaked graph display a little bit",
    ],
  },
  {
    date: "September 16, 2020",
    changes: [
      "Updated algorithm for determining buy/sell prices",
      <span>Added <Link to="/api" className={css(styles.feedbackLink)}>API documentation</Link></span>,
      "Added a CSV Export API",
    ],
  },
];

export default function About() {
  return (
    <div className={css(sharedStyles.wrapper)}>
      <Header />
      <div className={css(styles.itemHeader)}>About</div>
      <div className={css(styles.about)}>
        <p>
          This is a fan page made for the game EVE Echoes. It is difficult to
          access market information about important resources in-game, so this
          website attempts to make that information easier to access.
        </p>
        <p>
          The website is a work in progress, so parts of it will change over
          time. If you'd like to give feedback or report bugs, submit your
          feedback through this form:{" "}
          <a className={css(styles.feedbackLink)} href="https://forms.gle/mgsdSmvAnwQGCpTL9">Feedback Form</a>
        </p>
        <p>
          Hopefully you find the website useful! Cheers.
        </p>
      </div>
      <div className={css(styles.itemHeader, styles.changelog)}>Changelog</div>
      <div className={css(styles.about)}>
        {changelog.map(log => (
          <p key={log.date}>
            {log.date}
            <ul>
              {log.changes.map((change, i) => (
                <li key={i}>
                  {change}
                </li>
              ))}
            </ul>
          </p>
        ))}
      </div>
    </div>
  );
}

import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { colors, sharedStyles } from "./styles.js";
import Header from "./Header.js";

const styles = StyleSheet.create({
  itemHeader: {
    borderBottom: `1px solid ${colors.blue}`,
    padding: '4px 10px',
    color: colors.text,
    textAlign: 'center',
    fontSize: 24,
  },

  about: {
    color: colors.text,
    padding: '0 16px',
  },

  feedbackLink: {
    color: colors.blue,
  },
});

export default function API() {
  return (
    <div className={css(sharedStyles.wrapper)}>
      <Header />
      <div className={css(styles.itemHeader)}>API Documentation</div>
      <div className={css(styles.about)}>
        <p>
          Note: This API is subject to change rapidly over time. I will try to
          avoid breaking changes, but as new info comes in and heuristics
          improve over time, they might happen.
        </p>
        <p>
          The hostname for all API methods is <strong>https://api.eve-echoes-market.com/</strong>
        </p>
        <h2>Available methods</h2>
        <p>
          <a className={css(styles.feedbackLink)} href="#item-stats">Item stats and history</a>
        </p>
        <p>
          <a className={css(styles.feedbackLink)} href="#csv-export">CSV export</a>
        </p>
        <h2 id="item-stats">Item stats and history</h2>
        <p>
          <strong>/market-stats/&lt;item_id&gt;</strong>
        </p>
        <p>
          This will give market history information about a specific item. It
          returns a JSON-encoded array of item stats. Each element in the array
          is an object with the properties:

          <ul>
            <li><strong>time</strong> - a UNIX timestamp (seconds)</li>
            <li><strong>sell</strong> - a calculated sell price for the item</li>
            <li><strong>buy</strong> - a calculated buy price for the item</li>
            <li><strong>lowest_sell</strong> - the lowest sell price for the item</li>
            <li><strong>highest_buy</strong> - the highest buy price for the item</li>
            <li><strong>volume</strong> - a predicted volume for the item</li>
          </ul>
        </p>
        <h2 id="csv-export">CSV Export</h2>
        <p>
          <strong>/market-stats/stats.csv</strong>
        </p>
        <p>
          This will give a snapshot of the entire market at the current time.
          It returns a CSV file with one row for each market item.
        </p>
        <p>
          See{" "}
          <a
            href="https://docs.google.com/spreadsheets/d/1I8gWQy0UYWT36CtHRqb2u9vcCvWWtHyzkEnfOoVY-N4/edit?usp=sharing"
            className={css(styles.feedbackLink)}
          >
            this Google Sheet
          </a>
          {" "}for an example of how to use the IMPORTDATA() function to
          automatically retrieve the prices of items.
        </p>
        <p>
          The CSV file contains the following columns:

          <ul>
            <li><strong>item_id</strong> - the ID of the item</li>
            <li><strong>name</strong> - the name of the item in English</li>
            <li><strong>time</strong> - the last time the stats for the item were updated</li>
            <li><strong>sell</strong> - the most recent calculated sell price for the item</li>
            <li><strong>buy</strong> - the most recent calculated buy price for the item</li>
            <li><strong>lowest_sell</strong> - the most recent lowest seen sell price for the item</li>
            <li><strong>highest_buy</strong> - the most recent highest seen buy price for the item</li>
          </ul>
        </p>
      </div>
    </div>
  );
}

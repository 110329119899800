import React from 'react';
import { css, StyleSheet } from 'aphrodite';

const fontAwesomeFont = {
  fontFamily: "FontAwesome",
  fontStyle: "normal",
  fontWeight: "normal",
  src: [
    "url('/fa-solid-900.woff2') format('woff2')",
    "url('/fa-solid-900.woff') format('woff')",
    "url('/fa-solid-900.ttf') format('truetype')",
  ].join(","),
};

const styles = StyleSheet.create({
  icon: {
    fontFamily: fontAwesomeFont,
  },
});

const iconNameToChar = {
  search: "\uf002",
  hamburger: "\uf0c9",
  chevron: "\uf077",
  cancel: "\uf00d",
  arrowLeft: "\uf060",
  spinner: "\uf110",
};

export default function Icon({ name, style }) {
  return <span className={css(styles.icon, style)}>{iconNameToChar[name]}</span>;
}

import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import About from "./About.js";
import API from "./API.js";
import App from "./App.js";
import MarketStatsReminder from "./MarketStatsReminder.js";

function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/api">
          <API />
        </Route>
        <Route path="/market-stats">
          <MarketStatsReminder />
        </Route>
        <Route path="/">
          <App />
        </Route>
      </Switch>
    </Router>
  );
}

export default AppRouter;

import { StyleSheet } from 'aphrodite';

export const colors = {
  text: "#E8E8E8",
  subtext: "#B8B8B8",
  blue: "#3A83B0",
  green: "#44AB76",
  orange: "#DEA350",
  darkOrange: "#755220",
  darkBlue: "#174059",
};

export const oxygenFont = {
  fontFamily: "Oxygen",
  fontStyle: "normal",
  fontWeight: "normal",
  src: "url('/Oxygen-Regular.ttf') format('truetype')",
};

export const sharedStyles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: colors.darkBlue,
    minHeight: '100vh',
    fontFamily: oxygenFont,
  },
});
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { css, StyleSheet } from 'aphrodite';
import Highcharts from 'highcharts';
import moment from 'moment-timezone';
import _ from 'lodash';
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { colors, sharedStyles, oxygenFont } from "./styles.js";
import Header from "./Header.js";
import Icon from "./Icon.js";

import GROUP_DATA from './groups.json';

const ALL_GROUPS = GROUP_DATA.groups;
const ITEM_PATHS = GROUP_DATA.item_paths;
const ITEM_NAMES = GROUP_DATA.item_names;

const spinAnimation = {
  '0%': {
    transform: "rotate(0deg)",
  },

  '100%': {
    transform: "rotate(360deg)",
  },
};

const styles = StyleSheet.create({
  search: {
    backgroundColor: colors.blue,
    height: 40,
    width: 40,
    borderRadius: 20,
    fontSize: '24px',
    lineHeight: '40px',
    color: colors.text,
    textAlign: 'center',
    paddingRight: 2,
    paddingBottom: 2,
    cursor: "pointer",
  },

  toolbar: {
    padding: 5,
    borderBottom: `10px solid ${colors.blue}`,
    display: 'flex',
    alignItems: 'center',
  },

  group: {
    borderBottom: `1px solid ${colors.blue}`,
    height: 50,
    color: colors.text,
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    paddingRight: 10,
    cursor: "pointer",
  },

  groupName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  groupChevronUnexpanded: {
    color: colors.green,
    transform: 'rotate(180deg)',
    marginLeft: 20,
    marginRight: 10,
  },

  groupChevronExpanded: {
    color: colors.green,
    marginLeft: 20,
    marginRight: 10,
  },

  iconChevron: {
    color: colors.orange,
    marginLeft: 20,
    marginRight: 10,
    transform: 'rotate(90deg)',
  },

  cancel: {
    color: colors.orange,
    marginLeft: 4,
    fontSize: '28px',
    cursor: "pointer",
  },

  searchBar: {
    height: 40,
    marginLeft: 4,
    appearance: "none",
    border: `2px solid ${colors.blue}`,
    backgroundColor: "#ccc",
    borderRadius: 4,
    fontFamily: oxygenFont,
    fontSize: '20px',
    flex: 1,
    paddingLeft: 4,
    paddingRight: 4,

    ":focus": {
      outline: "none",
      backgroundColor: "#e8e8e8",
    },
  },

  itemHeader: {
    borderBottom: `1px solid ${colors.blue}`,
    padding: '4px 10px',
    color: colors.text,
    textAlign: 'center',
    fontSize: 24,
  },

  backButton: {
    marginRight: "auto",
    fontSize: 20,
    backgroundColor: colors.blue,
    height: 40,
    borderRadius: 4,
    lineHeight: "40px",
    paddingRight: 10,
    paddingLeft: 10,
    color: colors.text,
  },

  stats: {
    borderBottom: `1px solid ${colors.blue}`,
  },

  statsRow: {
    display: "flex",
    flexDirection: "row",
    margin: '16px 10px',
    color: colors.text,
  },

  statsHeader: {
    marginRight: 20,
    fontSize: 20,
    fontWeight: "bold",
    flex: 1,
    textAlign: "right",
  },

  statsValue: {
    flex: 1,
    fontSize: 20,
  },

  lastUpdated: {
    display: "flex",
    justifyContent: "center",
    margin: '16px 10px',
    color: colors.subtext,
    fontSize: 14,
  },

  chart: {
    marginBottom: 10,
  },

  chartTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: colors.text,
    textAlign: "center",
    marginTop: 8,
  },

  loading: {
    color: colors.text,
    fontSize: 24,
    marginTop: 24,
    textAlign: "center",
  },

  spinner: {
    fontSize: 30,
    animationName: spinAnimation,
    animationDuration: "1s",
    animationIterationCount: "infinite",
    display: "inline-block",
    animationTimingFunction: "ease",
    marginRight: 5,
  },
});

function formatISK(number) {
  if (number < 1000) {
    return `${number} ISK`;
  } else if (number < 1000000) {
    return `${(number / 1000).toFixed(1)}K ISK`;
  } else if (number < 1000000000) {
    return `${(number / 1000000).toFixed(1)}M ISK`;
  } else {
    return `${(number / 1000000000).toFixed(1)}B ISK`;
  }
}

function ItemInfo() {
  const params = useParams();

  const [stats, setStats] = useState(null);
  const chartRef = useRef();

  const itemId = parseInt(params.itemId);

  useEffect(() => {
    setStats(null);

    async function fetchStats() {
      const response = await fetch(`https://api.eve-echoes-market.com/market-stats/${itemId}`);
      const data = await response.json();

      setStats(data);
    }

    fetchStats();
  }, [itemId]);

  useEffect(() => {
    if (stats) {
      Highcharts.chart(chartRef.current, {
        title: {
          text: null,
        },

        time: {
          timezone: moment.tz.guess(),
          useUTC: false,
        },

        chart: {
          backgroundColor: colors.darkBlue,
        },

        tooltip: {
          shared: true,
        },

        legend: {
          verticalAlign: "top",
          itemStyle: {
            color: colors.text,
            fontSize: "12px",
            fontWeight: "normal",
          },
        },

        series: [
          {
            name: 'Buy price',
            data: stats.map(entry => ({
              x: Math.round(entry.time / 3600) * 3600 * 1000,
              y: entry.buy,
            })),
            color: colors.blue,
            turboThreshold: 5000,
          },
          {
            name: 'Sell price',
            data: stats.map(entry => ({
              x: Math.round(entry.time / 3600) * 3600 * 1000,
              y: entry.sell,
            })),
            color: colors.orange,
            turboThreshold: 5000,
          },
          {
            name: 'Volume',
            type: 'column',
            yAxis: 1,
            pointRange: 4 * 3600 * 1000,
            data: stats.filter(entry => entry.volume != null).map(entry => ({
              x: Math.round(entry.time / 3600) * 3600 * 1000,
              y: entry.volume,
            })),
            color: colors.green,
            borderColor: "rgba(0, 0, 0, 0)",
            turboThreshold: 5000,
          },
        ],

        yAxis: [{
          title: {
            text: null,
          },
          height: '65%',
          labels: {
            style: {
              color: colors.text,
            },
          },
          gridLineColor: "#777777",
        }, {
          top: '75%',
          height: '25%',
          offset: 0,
          endOnTick: false,
          title: {
            text: null,
          },
          labels: {
            enabled: false,
          },
          gridLineColor: "#777777",
        }],

        xAxis: {
          type: 'datetime',
          labels: {
            style: {
              color: colors.text,
            },
          },
          lineColor: "#777777",
          max: Date.now(),
        },
      });
    }
  }, [stats])

  const mostRecent = stats && stats[stats.length - 1];

  return (
    <div>
      <div className={css(styles.itemHeader)}>
        <span>{ITEM_NAMES[itemId].name_en}</span>
      </div>
      {!mostRecent && (
        <div className={css(styles.loading)}>
          <Icon name="spinner" style={styles.spinner} /> Loading...
        </div>
      )}
      {mostRecent && (
        <>
          <div className={css(styles.stats)}>
            <div className={css(styles.statsRow)}>
              <span className={css(styles.statsHeader)}>Buy Price</span>
              <span className={css(styles.statsValue)}>{formatISK(mostRecent.buy)}</span>
            </div>
            <div className={css(styles.statsRow)}>
              <span className={css(styles.statsHeader)}>Sell Price</span>
              <span className={css(styles.statsValue)}>{formatISK(mostRecent.sell)}</span>
            </div>
            {mostRecent.volume != null && (
              <div className={css(styles.statsRow)}>
                <span className={css(styles.statsHeader)}>Est. Volume</span>
                <span className={css(styles.statsValue)}>{mostRecent.volume.toLocaleString()}</span>
              </div>
            )}
            <div className={css(styles.lastUpdated)}>
              <span>Last Updated {moment(Math.round(mostRecent.time / 3600) * 3600 * 1000).fromNow()}</span>
            </div>
          </div>
          <div className={css(styles.chart)}>
            <div className={css(styles.chartTitle)}>Price History</div>
            <div ref={chartRef} />
          </div>
        </>
      )}
    </div>
  );
}

function ItemSelector({ item, level, onSelectItem }) {
  return (
    <div
      onClick={() => onSelectItem(item.id)}
      className={css(styles.group)}
      style={{ paddingLeft: level * 20 }}
    >
      <Icon name="chevron" style={styles.iconChevron} />
      <span className={css(styles.groupName)}>{item.name}</span>
    </div>
  );
}

function GroupDropdown({ group, level, expandedGroups, setGroupExpanded, ...props }) {
  const expanded = expandedGroups[group.id] || false;

  function toggleExpansion() {
    setGroupExpanded(group.id, !expanded);
  }

  if (Object.keys(group.contents).length === 0) {
    return null;
  }

  return (
    <>
      <div
        onClick={toggleExpansion}
        className={css(styles.group)}
        style={{ paddingLeft: level * 20 }}
      >
        <Icon name="chevron" style={expanded ? styles.groupChevronExpanded : styles.groupChevronUnexpanded} />
        <span className={css(styles.groupName)}>{group.name}</span>
      </div>
      {expanded && Object.keys(group.contents).map(id =>
        <GroupOrItem
          key={id}
          groupOrItem={group.contents[id]}
          level={level + 1}
          expandedGroups={expandedGroups}
          setGroupExpanded={setGroupExpanded}
          {...props}
        />
      )}
    </>
  );
}

function GroupOrItem({ groupOrItem, ...props }) {
  if (groupOrItem.kind === 'ITEM') {
    return <ItemSelector item={groupOrItem} {...props} />
  } else {
    return <GroupDropdown group={groupOrItem} {...props} />
  }
}

export default function App() {
  const [expandedGroups, setExpandedGroups] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedSearchGroups, setExpandedSearchGroups] = useState({});
  const [searchGroups, setSearchGroups] = useState({});

  const history = useHistory();
  const location = useLocation();

  const inputRef = useRef();

  const query = new URLSearchParams(location.search);

  const computeSearchGroupsDebounced = useCallback(
    _.debounce((searchTerm) => {
      const expandedSearchGroups = {};
      let searchGroups = {};
      if (searchTerm.length > 0) {
        const searchTermLower = searchTerm.toLowerCase();
        function matchesSearch(name) {
          return name.toLowerCase().includes(searchTermLower);
        }

        for (const [itemId, {name_en: name}] of Object.entries(ITEM_NAMES)) {
          if (matchesSearch(name) && ITEM_PATHS[itemId] != null) {
            for (const itemPath of ITEM_PATHS[itemId]) {
              expandedSearchGroups[itemPath] = true;
            }
          }
        }

        function getGroupContents(groups) {
          const result = {};

          for (const [id, group] of Object.entries(groups)) {
            if (group.kind === "ITEM") {
              if (matchesSearch(ITEM_NAMES[id].name_en)) {
                result[id] = group;
              }
            } else {
              if (expandedSearchGroups[id]) {
                result[id] = {
                  ...group,
                  contents: getGroupContents(group.contents),
                };
              }
            }
          }

          return result;
        }
        searchGroups = getGroupContents(ALL_GROUPS);
      }

      setSearchGroups(searchGroups);
      setExpandedSearchGroups(expandedSearchGroups);
    }, 250),
    []);

  useEffect(() => computeSearchGroupsDebounced(searchTerm), [searchTerm, computeSearchGroupsDebounced]);

  function setGroupExpanded(groupId, expanded) {
    setExpandedGroups({
      ...expandedGroups,
      [groupId]: expanded,
    });
  }

  function navigateToItem(itemId) {
    const itemName = ITEM_NAMES[itemId].name_en;
    const normalizedItemName = itemName.replace(/[^a-zA-Z0-9]+/g, '-');
    history.push(`/${itemId}/${normalizedItemName}`);
  }

  return (
    <div className={css(sharedStyles.wrapper)}>
      <Header />
      <Switch>
        <Route path="/:itemId/:name">
          <div className={css(styles.toolbar)}>
            <div
              className={css(styles.backButton)}
              onClick={() => history.push('/')}
            >
              <Icon name="arrowLeft" />
              {" "}Back to Browse
            </div>
            <div
              className={css(styles.search)}
              onClick={() => history.push(`/search?from=${encodeURIComponent(location.pathname)}`)}
            >
              <Icon name="search" />
            </div>
          </div>
          <ItemInfo />
        </Route>
        <Route path="/search">
          <div className={css(styles.toolbar)}>
            <div
              className={css(styles.search)}
            >
              <Icon name="search" />
            </div>
            <input
              type="text"
              className={css(styles.searchBar)}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              ref={inputRef}
              autoFocus
            />
            <div
              className={css(styles.cancel)}
              onClick={() => history.push(
                decodeURIComponent(query.get('from')) || '/')}
            >
              <Icon name="cancel" />
            </div>
          </div>
          <div>
            {Object.keys(searchGroups).map(groupId =>
              <GroupDropdown
                key={groupId}
                group={searchGroups[groupId]}
                level={0}
                expandedGroups={expandedSearchGroups}
                setGroupExpanded={() => {}}
                onSelectItem={(itemId) => {
                  const newExpandedGroups = {...expandedGroups}
                  for (const groupId of ITEM_PATHS[itemId]) {
                    newExpandedGroups[groupId] = true;
                  }
                  setExpandedGroups(newExpandedGroups);
                  navigateToItem(itemId);
                }}
              />
            )}
          </div>
        </Route>
        <Route path="/">
          <div className={css(styles.toolbar)}>
            <div
              className={css(styles.search)}
              onClick={() => history.push('/search')}
            >
              <Icon name="search" />
            </div>
          </div>
          <div>
            {Object.keys(ALL_GROUPS).map(groupId =>
              <GroupDropdown
                key={groupId}
                group={ALL_GROUPS[groupId]}
                level={0}
                expandedGroups={expandedGroups}
                setGroupExpanded={setGroupExpanded}
                onSelectItem={navigateToItem}
              />
            )}
          </div>
        </Route>
      </Switch>
    </div>
  );
}
